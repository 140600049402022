<div
  *ngIf="showDisclaimer && orderDetailsData?.selectedWholesaler"
  class="global-alerts"
>
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button
      (click)="closeDisclaimerAlert()"
      class="close closeAccAlert"
      aria-hidden="true"
      data-dismiss="alert"
      type="button"
      data-di-id="di-id-4f6d7b93-70563360"
    >
      ×
    </button>
    {{ "addToCartItems.disclaimerMsg" | cxTranslate }}
  </div>
</div>
<section class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 p0 details-header">
  <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0"></section>
  <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row p0">
    <img
      src="../../../assets/icons/arrow-left.svg"
      class="chevron-left"
      alt="left"
    />
    <a [routerLink]="['/my-account/orders']" href="javascript: void(0);">{{
      "orderDetail.backToOrder" | cxTranslate
    }}</a>
  </section>
  <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 p0">
    <section class="save-document mb1 mt0">
      <ng-template #popSaveTitle>{{
        "orderDetail.orderName" | cxTranslate
      }}</ng-template>
      <ng-template #popSaveContent>
        <input type="text" class="save-order" [(ngModel)]="saveCartName" />
        <span class="common-error required">{{ errorMsg }}</span>
        <button
          type="reset"
          class="popover-close btn white-button popover-cancel-button"
          (click)="toggle(p1)"
        >
          {{ "orderDetail.cancel" | cxTranslate }}
        </button>
        <button
          class="btn blue-button popover-cancel-button"
          type="submit"
          data-di-id="di-id-3064f17d-e97cc628"
          (click)="saveOrder()"
        >
          {{ "orderDetail.save" | cxTranslate }}
        </button>
      </ng-template>
      <a (click)="excelDownload()"
        ><img src="../../../assets/icons/excel.png" alt="XLS" />
        <span>{{ "orderDetail.xls" | cxTranslate }}</span></a
      >
      <a (click)="print()"
        ><img src="../../../assets/icons/print.svg" alt="Print" />
        <span>{{ "orderDetail.print" | cxTranslate }}</span></a
      >
    </section>
  </section>
</section>
<section class="row row-20 leftCont-rightNav display-block">
  <aside class="col-sm-4 col-md-4 right-navigation float-right">
    <section class="savings-total">
      <span
        class="shipTo-Header total-Amount-Span"
        *ngIf="
          orderDetailsData?.cartType === 'INDIRECT' &&
            orderDetailsData?.subTotal?.value ==
              orderDetailsData?.totalPriceWithTax?.value;
          else otherCartType
        "
      >
        <h3>
          {{ "orderDetail.TotalHt" | cxTranslate }}
          {{ orderDetailsData?.totalPriceWithTax?.value | localCurrency }}
        </h3>
      </span>
      <ng-template #otherCartType>
        <h3>
          {{ "orderDetail.orderTotal" | cxTranslate }}
          {{ orderDetailsData?.totalPriceWithTax?.value | localCurrency }}
        </h3>
      </ng-template>
      <button
        type="button"
        class="btn btn-default blue-button"
        *ngIf="
          orderDetailsData &&
          shouldDisplayButton(
            orderDetailsData.cartType,
            isFluPreOrderSeasonOpen,
            orderDetailsData.entries
          ) &&
          activeOrderProducts
        "
        (click)="openModal(content)"
      >
        {{ "orderDetail.reorderCheckout" | cxTranslate }}
      </button>
      <button
        type="submit"
        class="btn btn-default white-button"
        *ngIf="
          orderDetailsData &&
          shouldDisplayButton(
            orderDetailsData.cartType,
            isFluPreOrderSeasonOpen,
            orderDetailsData.entries
          ) &&
          activeOrderProducts
        "
        (click)="getCartId('addCart')"
      >
        {{ "orderDetail.addToCart" | cxTranslate }}
      </button>
    </section>
    <section
      class="savings-total row hidden-sm"
      *ngIf="orderDetailsData?.authorizeDocument"
    >
      <div class="col-md-12 cols-xs-12 download-doc-copy">
        <p>{{ "orderDetail.downloadCopy" | cxTranslate }}</p>
      </div>
      <div class="col-md-6 col-xs-6 text-left file-name">
        <p>{{ orderDetailsData?.authorizeDocument.name }}</p>
      </div>
      <div class="col-md-6 col-xs-6 text-right download-link">
        <a
          (click)="
            downloadFile(
              {
                code: orderDetailsData?.authorizeDocument.code,
                url: orderDetailsData?.authorizeDocument.url,
                name: orderDetailsData?.authorizeDocument.name
              },
              orderDetailsData?.authorizeDocument.name
            )
          "
        >
          <img
            _ngcontent-serverapp-c423=""
            src="../../../assets/icons/download-icon.svg"
            alt="download"
          />{{ "orderDetail.download" | cxTranslate }}
        </a>
      </div>
    </section>

    <!-- Edit flu order -->
    <section class="row justify-content-center mt-3">
      <section class="col-10 text-center">
        <ng-container *ngIf="editButtonConfig | async as config">
          <div
            *ngIf="
              config?.isUpdateAllowed &&
                orderDetailsData?.cartType === 'INFLUENZA' &&
                !hospital;
              else editReservationMax
            "
          >
            <button
              class="btn btn-default blue-button"
              *ngIf="showButton"
              (click)="fluEditReserve(config?.totalMinDosesThreshold)"
            >
              {{ "orderConfirmation.editReservation" | cxTranslate }}
            </button>
          </div>
          <ng-template #editReservationMax>
            <div *ngIf="config?.availableUpdates === 0 && !hospital && config?.alreadyUpdated">
              <button
                class="btn blue-button resBtn text-button-initial mb-4 btn-disabled"
                disabled
              >
                {{ "orderConfirmation.editReservationMax" | cxTranslate }}
              </button>
            </div>
          </ng-template>
        </ng-container>
      </section>
    </section>
    <!-- Modal Start -->
    <ng-template #content let-modal>
      <section class="modal-header">
        <h4 class="modal-title-top20">
          {{ "orderDetail.reorder" | cxTranslate }}
        </h4>
      </section>
      <section class="modal-body reorder">
        <section class="text-center">
          <p>{{ "orderDetail.clearCartDescription" | cxTranslate }}</p>
        </section>
      </section>

      <section class="modal-footer">
        <section class="miniCart-foot">
          <button
            type="submit"
            class="btn btn-default blue-button"
            (click)="clearChildCart()"
          >
            {{ "orderDetail.clearReorder" | cxTranslate }}
          </button>
          <button
            type="button"
            class="btn btn-default white-button"
            (click)="existingCart()"
          >
            {{ "orderDetail.existingCart" | cxTranslate }}
          </button>
        </section>
      </section>
    </ng-template>
    <!-- Modal End -->
  </aside>

  <section class="col-sm-8 col-md-8 left-section float-left">
    <section class="print-preview">
      <section class="firstLevel-acc od-line-align">
        <section class="od-lable-align">
          <label class="fs-14 light-color">
            <img src="../../../assets/icons/location.svg" alt="loc" />
            {{ selectedData?.companyName }} ({{ selectedData?.unitID }})
          </label>
        </section>
        <section class="order-preview">
          <p *ngIf="!orderDetailsData?.selectedWholesaler; else whsOrder">
            {{ "orderDetail.order" | cxTranslate }}
            <span class="orderDetailsDataText">{{
              orderDetailsData?.guid
            }}</span>
          </p>
          <ng-template #whsOrder>
            <p>
              {{ "orderDetail.OrderNoReceivedFromSAP" | cxTranslate }}:
              <span class="orderDetailsDataText">{{
                orderDetailsData?.guid
              }}</span>
            </p>
          </ng-template>
          <p>
            {{ "orderDetail.orderDate" | cxTranslate }}
            <span>{{ orderDetailsData?.created | date : "dd/MM/yyyy" }}</span>
          </p>
        </section>
      </section>
      <section class="row ship-orderdata">
        <section class="col-sm-12 col-md-12 col-lg-12 p0 ship-status">
          <ul>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.orderStatus" | cxTranslate }}
              </h5>
              <p
                *ngIf="
                  orderDetailsData?.statusDisplay !== undefined &&
                  orderDetailsData?.statusDisplay !== ''
                "
              >
                {{ orderDetailsData?.statusDisplay }}
              </p>
              <p
                *ngIf="
                  orderDetailsData?.statusDisplay === undefined ||
                  orderDetailsData?.statusDisplay === ''
                "
              >
                -
              </p>
            </li>
            <li>
              <h5
                class="fs-12 dark-color ff-bold mt0"
                *ngIf="placedByAsm; else NA"
              >
                {{ "orderDetail.asmPlacedBy" | cxTranslate }}
              </h5>
              <ng-template #NA>
                <h5 class="fs-12 dark-color ff-bold mt0">
                  {{ "orderDetail.placedBy" | cxTranslate }}
                </h5>
              </ng-template>
              <p
                *ngIf="
                  orderDetailsData?.user?.name !== undefined &&
                  orderDetailsData?.user?.name !== '' &&
                  orderDetailsData?.user?.name !== 'default user'
                "
              >
                {{ orderDetailsData?.user?.name }}
              </p>
              <p
                *ngIf="
                  orderDetailsData?.user?.name === undefined ||
                  orderDetailsData?.user?.name === '' ||
                  orderDetailsData?.user?.name === 'default user'
                "
              >
                -
              </p>
              <span class="" *ngIf="placedByAsm"
                >({{ "orderDetail.asmBy" | cxTranslate }}
                {{ placedByAsm }})</span
              >
            </li>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.po" | cxTranslate }}
              </h5>
              <p
                *ngIf="
                  orderDetailsData?.purchaseOrderNumber !== undefined &&
                  orderDetailsData?.purchaseOrderNumber !== ''
                "
              >
                {{ orderDetailsData?.purchaseOrderNumber }}
              </p>
              <p
                *ngIf="
                  orderDetailsData?.purchaseOrderNumber === undefined ||
                  orderDetailsData?.purchaseOrderNumber === ''
                "
              >
                -
              </p>
            </li>
            <li *ngIf="orderDetailsData?.selectedWholesaler">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.orderNoReceived" | cxTranslate }}
              </h5>
              <p
                *ngIf="
                  orderDetailsData?.wholesalerOrderReference;
                  else whsOrderRef
                "
              >
                {{ orderDetailsData.wholesalerOrderReference }}
              </p>
              <ng-template #whsOrderRef>
                <p>-</p>
              </ng-template>
            </li>
          </ul>
        </section>
      </section>
      <section class="light-grey-line"></section>
      <h4 class="headerTextDetailsPage">
        {{
          orderDetailsData?.cartType !== "INDIRECT"
            ? ("orderDetail.billing" | cxTranslate)
            : ("orderDetail.billingIndirect" | cxTranslate)
        }}
      </h4>
      <section class="row row-20 ship-data">
        <section class="col-sm-12 col-md-5 col-lg-4">
          <ul>
            <li class="billingTo">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.billTo" | cxTranslate }}
              </h5>
              <p>{{ orderDetailsData?.billingAddress?.companyName }}</p>
              <p>
                {{ orderDetailsData?.billingAddress?.line1 }},
                {{
                  orderDetailsData?.billingAddress?.line2
                    ? orderDetailsData?.billingAddress?.line2 != "000000"
                      ? orderDetailsData?.billingAddress?.line2
                      : ""
                    : ""
                }}
              </p>
              <p>
                {{ orderDetailsData?.billingAddress?.town }},
                {{ orderDetailsData?.billingAddress?.postalCode }}
              </p>
            </li>
          </ul>
        </section>
        <section class="col-sm-12 col-md-5 col-lg-5 order-Subtotal">
          <section class="table-responsive">
            <table class="table" aria-describedby="orderDataTable">
              <thead>
                <tr>
                  <th scope="col" class="ff-bold">
                    {{ "orderDetail.orderSubtotal" | cxTranslate }}
                  </th>
                  <th scope="col" class="fw-normal order-price">
                    {{ orderDetailsData?.subTotal?.value | localCurrency }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let tax of orderDetailsData?.taxValues">
                  <tr
                    *ngIf="
                      orderDetailsData?.cartType === 'INFLUENZA' &&
                      orderDetailsData?.totalTax?.value !== 0 &&
                      orderDetailsData?.totalTax?.value !== undefined &&
                      tax?.value.value !== 0
                    "
                  >
                    <td class="ff-bold pt1 pricezero">
                      {{ "orderDetail.salesTax" | cxTranslate }}({{
                        tax?.percentage
                      }}%)
                    </td>
                    <td class="ff-bold pt1 order-price">
                      {{ tax?.value.value | localCurrency }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="
                      orderDetailsData?.cartType !== 'INFLUENZA' &&
                      orderDetailsData?.totalTax?.value !== 0 &&
                      orderDetailsData?.totalTax?.value !== undefined
                    "
                  >
                    <td class="ff-bold pt1 pricezero">
                      {{ "orderDetail.salesTax" | cxTranslate }}({{
                        tax?.percentage
                      }}%)
                    </td>
                    <td class="ff-bold pt1 order-price">
                      {{ tax?.value.value | localCurrency }}
                    </td>
                  </tr>
                </ng-container>
                <tr
                  *ngIf="
                    orderDetailsData?.deliveryCost != null &&
                    orderDetailsData?.deliveryCost?.value != 0
                  "
                >
                  <td class="ff-bold pt1">
                    {{ "orderDetail.freight" | cxTranslate }}
                  </td>
                  <td class="ff-bold pt1 order-price">
                    {{ orderDetailsData?.deliveryCost?.value | localCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="ff-bold pt1">
                    <span *ngIf="orderDetailsData?.cartType === 'STANDARD'">
                      {{ "orderDetail.orderTotals" | cxTranslate }}
                    </span>
                    <span
                      *ngIf="
                        orderDetailsData?.cartType === 'INDIRECT' &&
                        orderDetailsData?.subTotal?.value ==
                          orderDetailsData?.totalPriceWithTax?.value
                      "
                    >
                      {{ "orderDetail.TotalHt" | cxTranslate }}
                    </span>
                    <span
                      *ngIf="
                        orderDetailsData?.cartType === 'INDIRECT' &&
                        orderDetailsData?.subTotal?.value !=
                          orderDetailsData?.totalPriceWithTax?.value
                      "
                    >
                      {{ "orderDetail.orderTotals" | cxTranslate }}
                    </span>
                    <span *ngIf="orderDetailsData?.cartType === 'INFLUENZA'">
                      {{ "orderDetail.orderTotals" | cxTranslate }}
                    </span>
                  </td>
                  <td class="ff-bold pt1 order-price">
                    {{ orderDetailsData?.totalPrice?.value | localCurrency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </section>
      <section class="light-grey-line"></section>
      <h4 class="headerTextDetailsPage">
        {{
          orderDetailsData?.cartType !== "INDIRECT"
            ? ("orderDetail.shipping" | cxTranslate)
            : ("orderDetail.shippingIndirect" | cxTranslate)
        }}
      </h4>

      <section class="row row-20 ship-data">
        <section class="col-sm-12 col-md-5 col-lg-4">
          <ul>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.shipTo" | cxTranslate }}
              </h5>
              <p>{{ orderDetailsData?.deliveryAddress?.companyName }}</p>
              <p>
                {{ orderDetailsData?.deliveryAddress?.line1 }},
                {{
                  orderDetailsData?.deliveryAddress?.line2
                    ? orderDetailsData?.deliveryAddress?.line2 != "000000"
                      ? orderDetailsData?.deliveryAddress?.line2
                      : ""
                    : ""
                }}
              </p>
              <p>
                {{ orderDetailsData?.deliveryAddress?.town }},
                {{ orderDetailsData?.deliveryAddress?.postalCode }}
              </p>
            </li>
          </ul>
        </section>
        <section
          *ngIf="
            orderDetailsData &&
            orderDetailsData.cartType !== 'INDIRECT' &&
            orderDetailsData.cartType !== 'INFLUENZA' &&
            (orderDetailsData.status == 'COMPLETED' ||
              orderDetailsData.status == 'PARTIALLY_COMPLETED' ||
              orderDetailsData.status == 'CLOSED')
          "
          class="col-sm-12 col-md-5 col-lg-4 delivery-notes-box"
        >
          <div class="delivery-notes-title">
            {{ "orderDetail.deliveryNotes" | cxTranslate }}
          </div>
          <a
            class="link delivery-notes-link"
            placement="top"
            (click)="loadDeliveryNotes(orderDetailsData.code)"
            [ngbPopover]="popContent"
            popoverTitle="{{ 'orderDetail.deliveryNotesHead' | cxTranslate }}"
          >
            {{ "orderDetail.downloadPdf" | cxTranslate }}
          </a>
          <ng-template #popContent>
            <table
              class="tb"
              id="delivery-notes-pop"
              aria-describedby="deliveryNotes"
            >
              <thead>
                <th scope="col">{{ "ordersList.dateBl" | cxTranslate }}</th>
                <th scope="col">{{ "ordersList.refBl" | cxTranslate }}</th>
              </thead>
              <tbody>
                <tr *ngFor="let dNote of orderDeliveryNotes; let i = index">
                  <td>{{ dNote.date }}</td>
                  <td>
                    <a
                      class="link delivery-notes-download"
                      (click)="downloadDeliveryNotePdf(dNote.url)"
                    >
                      {{ dNote.id }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </section>

        <section
          class="col-sm-12 col-md-5 col-lg-5 order-Subtotal"
          *ngIf="
            orderDetailsData?.selectedWholesaler &&
            (orderDetailsData?.selectedWholesaler?.locName ||
              orderDetailsData?.selectedWholesaler?.name)
          "
        >
          <h5 class="fs-12 dark-color ff-bold mt0">
            {{ "orderDetail.nameOfTheWholesaler" | cxTranslate }}
          </h5>
          <p>
            {{
              orderDetailsData?.selectedWholesaler?.locName ||
                orderDetailsData?.selectedWholesaler?.name
            }}
          </p>
        </section>
      </section>
      <section
        class="light-grey-line hidden-md"
        *ngIf="orderDetailsData?.authorizeDocument"
      ></section>
      <section
        class="row hidden-md"
        *ngIf="orderDetailsData?.authorizeDocument"
      >
        <div class="col-md-12 download-doc-copy">
          <p>{{ "orderDetail.downloadCopy" | cxTranslate }}</p>
        </div>
        <div class="col-xs-6 col-md-6 text-left file-name">
          <p>{{ orderDetailsData?.authorizeDocument.name }}</p>
        </div>
        <div class="col-xs-6 col-md-6 text-right download-link">
          <a
            (click)="
              downloadFile(
                {
                  code: orderDetailsData?.authorizeDocument.code,
                  url: orderDetailsData?.authorizeDocument.url,
                  name: orderDetailsData?.authorizeDocument.name
                },
                orderDetailsData?.authorizeDocument.name
              )
            "
          >
            <img
              _ngcontent-serverapp-c423=""
              src="../../../assets/icons/download-icon.svg"
              alt="download"
            />{{ "orderDetail.download" | cxTranslate }}
          </a>
        </div>
      </section>

      <ul
        class="checkout-details checkout-details-view"
        *ngFor="let item of orderDetailsData?.entries; let i = index"
      >
        <li *ngIf="checkEntriesExist(item) && item.quantity">
          <section class="row row-20">
            <section
              class="col-sm-12 col-md-12 col-lg-12 display-table pd_img pr15"
            >
              <a
                class="in-active"
                title="{{ item?.product?.name }}"
                (click)="openProductDetails(item)"
              >
                <img
                  class="flu-poster"
                  *ngIf="
                    item?.product.images !== undefined &&
                    item?.PRIMARY !== undefined
                  "
                  src="{{ item?.PRIMARY.url }}"
                  alt="{{ item?.product?.name }}"
                />
                <img
                  class="flu-poster"
                  *ngIf="
                    item?.product.images === undefined &&
                    item?.PRIMARY === undefined
                  "
                  [src]="fallbackImage"
                  alt="{{ item?.product?.name }}"
                />
                <h2 [innerHTML]="getProductName(item?.product?.name)"></h2>
              </a>
            </section>
          </section>
          <section class="row row-20 pr15">
            <section class="col-sm-12 col-md-1 col-lg-1 device-hide"></section>
            <section class="col-sm-12 col-md-11 col-lg-8 prod-number">
              <section
                class="checkout-data"
                [innerHtml]="getDescription(item?.product?.description)"
              ></section>
              <p class="pd_num" *ngIf="!hospital && item?.product?.cipCode">
                {{ "orderDetail.productNumber" | cxTranslate }}
                <span class="num-bold productCode">{{
                  item?.product?.cipCode
                }}</span>
              </p>
              <p class="pd_num" *ngIf="hospital && item?.product?.ucdCode">
                {{ "orderDetail.productUcdNumber" | cxTranslate }}
                <span class="num-bold productCode">{{
                  item?.product?.ucdCode
                }}</span>
              </p>
              <section
                class="message-order-history-area"
                *ngIf="item?.product?.messageDangerousGoods"
              >
                {{ "plp.messageDangerousGoods" | cxTranslate }}
              </section>
              <section
                class="message-order-history-area"
                *ngIf="item?.product?.messageColdChain"
              >
                {{ "plp.messageColdChain" | cxTranslate }}
              </section>
            </section>
          </section>

          <!-- Desktop Table -->
          <section
            class="row row-20 details-Table"
            *ngIf="checkEntriesExist(item)"
          >
            <section class="col-md-12 orderDetailTable">
              <section class="dataTables-wrapper">
                <!-- Message Display Logic -->
                <section
                  *ngIf="showQuantityMessage(item.unconsignedEntries, item.prodConsignments)"
                  [ngClass]="'italic-message'">
                  {{ "orderDetail.showQuantityMessage" | cxTranslate }}
                </section>

                <table class="mt-20" aria-describedby="orderTable">
                  <thead>
                    <ng-container *ngFor="let data of item?.prodConsignments">
                      <tr
                        class="heading"
                        *ngIf="
                          data.quantityDeclined == null ||
                          data.quantityDeclined == 0
                        "
                      >
                        <th scope="col" class="text-center">
                          {{ "orderDetail.status" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "orderDetail.totalUnits" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "orderDetail.confirmed" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "orderDetail.shippedUnits" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "orderDetail.shipDate" | cxTranslate }}
                        </th>
                        <th *ngIf="!hospital" scope="col" class="text-center">
                          {{ "orderDetail.yourPrice" | cxTranslate }}
                        </th>
                        <th *ngIf="hospital" scope="col" class="text-center">
                          {{ "orderDetail.ucdPrice" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-center">
                          {{ "orderDetail.subtotal" | cxTranslate }}
                        </th>
                      </tr>
                    </ng-container>
                    <tr
                      class="heading"
                      *ngIf="item?.prodConsignments.length == 0"
                    >
                      <th scope="col" class="text-center">
                        {{ "orderDetail.status" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.totalUnits" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.confirmed" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.shippedUnits" | cxTranslate }}
                      </th>
                      <th
                        scope="col"
                        class="text-center"
                        *ngIf="orderDetailsData?.cartType !== 'INDIRECT'"
                      >
                        {{ "orderDetail.shipDate" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center" *ngIf="!hospital">
                        {{ "orderDetail.yourPrice" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center" *ngIf="hospital">
                        {{ "orderDetail.ucdPrice" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.subtotal" | cxTranslate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="datatable-row">
                    <ng-container *ngFor="let data of item?.unconsignedEntries">
                      <tr
                        class="details-col"
                        *ngIf="
                          data?.quantityStatus != 'Annulé' && data?.quantity > 0
                        "
                      >
                        <td
                          class="text-center"
                          *ngIf="item?.product?.productType === 'GOODIES'"
                        >
                          {{ "orderDetail.statusOpen" | cxTranslate }}
                        </td>
                        <td
                          class="text-center"
                          *ngIf="item?.product?.productType !== 'GOODIES'"
                        >
                          {{ data?.quantityStatus }}
                        </td>
                        <td class="text-center">{{ data?.quantity }}</td>
                        <td class="text-center">
                          <span
                            *ngIf="
                              item?.product?.productType !== 'GOODIES' &&
                              data?.quantityStatus != 'En attente'
                            "
                          >
                            {{ data?.confirmedQuantity }}
                          </span>
                          <span *ngIf="item?.product?.productType === 'GOODIES'"
                            >&nbsp;</span
                          >
                        </td>
                        <td class="text-center"></td>
                        <td
                          class="text-center"
                          *ngIf="
                            orderDetailsData?.cartType !== 'INDIRECT' &&
                            item?.product?.productType !== 'GOODIES'
                          "
                        >
                          <span
                            *ngIf="
                              orderDetailsData?.cartType === 'INFLUENZA' &&
                              data?.quantityStatus != 'En attente' &&
                              data?.quantityStatus != 'En cours de préparation'
                            "
                          >
                            S{{ data?.confirmedDate | weekNumber }}<br />
                            <span *ngIf="data?.confirmedDate !== ''">
                              ({{ "orderDetail.listConfirmed" | cxTranslate }})
                            </span>
                          </span>
                          <span
                            *ngIf="
                              orderDetailsData?.cartType !== 'INFLUENZA' &&
                              data?.quantityStatus != 'Ouverte' &&
                              data?.quantityStatus != 'En attente' &&
                              data?.quantityStatus != 'En cours de préparation'
                            "
                          >
                            {{ data?.confirmedDate }}<br />
                            <span
                              *ngIf="
                                data?.confirmedDate !== '' &&
                                data?.quantityStatus != 'En attente' &&
                                data?.quantityStatus !=
                                  'En cours de préparation'
                              "
                            >
                              ({{ "orderDetail.listConfirmed" | cxTranslate }})
                            </span>
                          </span>
                        </td>
                        <td
                          class="text-center"
                          *ngIf="item?.product?.productType === 'GOODIES'"
                        >
                          &nbsp;
                        </td>
                        <td class="text-center">
                          {{ data?.basePrice?.value | localCurrency }}
                        </td>
                        <td class="text-center">
                          {{ data?.totalPrice?.value | localCurrency }}
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngFor="let data of item?.prodConsignments">
                      <tr
                        class="details-col"
                        *ngIf="
                          data.quantityDeclined == null ||
                          (data.quantityDeclined == 0 &&
                            data?.orderEntry?.quantity > 0)
                        "
                      >
                        <td class="text-center">{{ data?.status }}</td>
                        <td class="text-center">
                          <span class="td-hide">{{
                            data?.orderEntry?.quantity
                          }}</span>
                        </td>
                        <td class="text-center">
                          {{ data?.orderEntry?.confirmedQuantity }}
                        </td>
                        <td class="text-center">
                          {{ data?.shippedQuantity }}
                        </td>
                        <td class="text-center">
                          {{
                            data?.statusDate
                              | slice : 0 : 10
                              | date : "dd/MM/yyyy"
                          }}
                          <span *ngIf="prodEntries?.statusDate !== ''"></span>
                        </td>
                        <td class="text-center">
                          <span class="td-hide">{{
                            data?.orderEntry?.basePrice?.value | localCurrency
                          }}</span>
                        </td>
                        <td class="text-center">
                          <span class="td-hide">{{
                            data?.orderEntry?.totalPrice?.value | localCurrency
                          }}</span>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </section>
            </section>
          </section>
          <!-- Mobile table -->
          <section class="mobile-table hidden-md-block">
            <ng-container *ngFor="let data of item?.unconsignedEntries">
              <section class="mobile-table-body">
                <p>
                  <label for="orderStatus">{{ "orderDetail.status" | cxTranslate }}</label>
                  <span>{{ data?.quantityStatus }}</span>
                </p>
                <p class="sec-hide">
                  <label for="orderDetailTotalUnits">{{ "orderDetail.totalUnits" | cxTranslate }}</label>
                  <span>{{ data?.quantity }}</span>
                </p>
                <p>
                  <label for="orderDetailConfirmed">{{ "orderDetail.confirmed" | cxTranslate }}</label>
                  <span></span>
                </p>
                <p>
                  <label for="orderDetailShippedUnits">{{ "orderDetail.shippedUnits" | cxTranslate }}</label>
                  <span></span>
                </p>
                <p>
                  <label for="orderDetailShipDate">{{ "orderDetail.shipDate" | cxTranslate }}</label>
                  <span *ngIf="data?.quantityStatus != 'Ouverte'"
                    >{{ data?.confirmedDate }}
                    <span *ngIf="data?.confirmedDate !== ''"
                      >({{ "orderDetail.listConfirmed" | cxTranslate }})</span
                    ></span
                  >
                </p>
                
                <p class="sec-hide">
                  <label *ngIf="!hospital" for="orderDetailYourPrice">{{
                    "orderDetail.yourPrice" | cxTranslate
                  }}</label>
                  <label *ngIf="hospital" for="orderDetailUcdPrice">{{
                    "orderDetail.ucdPrice" | cxTranslate
                  }}</label>
                  <span>{{ data?.basePrice?.value | localCurrency }}</span>
                </p>
                <p class="sec-hide">
                  <label for="orderDetailSubtotal">{{ "orderDetail.subtotal" | cxTranslate }}</label>
                  <span>{{ data?.totalPrice?.value | localCurrency }}</span>
                </p>
              </section>
            </ng-container>
            <ng-container *ngFor="let data of item?.prodConsignments">
              <section
                class="mobile-table-body"
                *ngIf="
                  data.quantityDeclined == null || data.quantityDeclined == 0
                "
              >
                <p>
                  <label for="orderDetailStatus">{{ "orderDetail.status" | cxTranslate }}</label>
                  <span>{{ data?.status }}</span>
                </p>
                <p class="sec-hide">
                  <label for="orderDetailTotalUnits">{{ "orderDetail.totalUnits" | cxTranslate }}</label>
                  <span>{{ data?.orderEntry?.quantity }}</span>
                </p>
                <p>
                  <label for="orderDetailConfirmed">{{ "orderDetail.confirmed" | cxTranslate }}</label>
                  <span>{{ data?.orderEntry?.quantity }}</span>
                </p>
                <p>
                  <label for="orderDetailShippedUnits">{{ "orderDetail.shippedUnits" | cxTranslate }}</label>
                  <span>{{ data?.shippedQuantity }}</span>
                </p>
                <p>
                  <label for="orderDetailShipDate">{{ "orderDetail.shipDate" | cxTranslate }}</label>
                  <span
                    >{{
                      data?.statusDate | slice : 0 : 10 | date : "dd/MM/yyyy"
                    }}
                    <span *ngIf="data?.statusDate !== ''"></span
                  ></span>
                </p>
                <p class="sec-hide">
                  <label *ngIf="!hospital" for="orderDetailYourPrice">{{
                    "orderDetail.yourPrice" | cxTranslate
                  }}</label>
                  <label *ngIf="hospital" for="orderDetailUcdPrice">{{
                    "orderDetail.ucdPrice" | cxTranslate
                  }}</label>
                  <span>{{
                    data?.orderEntry?.basePrice?.value | localCurrency
                  }}</span>
                </p>
                <p class="sec-hide">
                  <label for="orderDetailSubtotal">{{ "orderDetail.subtotal" | cxTranslate }}</label>
                  <span>{{
                    data?.orderEntry?.totalPrice?.value | localCurrency
                  }}</span>
                </p>
              </section>
            </ng-container>
          </section>
        </li>
      </ul>

      <!-- Cancelled Order Product Desktop-->
      <ul
        class="checkout-details checkout-details-view"
        *ngIf="cancelledEntries.length > 0 && hasCancelledQuantities()"
      >
        <li>
          <section class="row row-20 details-Table">
            <section class="col-md-12 orderDetailTable">
              <section class="dataTables-wrapper">
                <h4 class="headerTextDetailsPage">
                  {{ "orderDetail.cancelledProducts" | cxTranslate }}
                </h4>
                <table>
                  <thead>
                    <tr class="heading">
                      <th>{{ "orderDetail.status" | cxTranslate }}</th>
                      <th>{{ "orderDetail.productName" | cxTranslate }}</th>
                      <th *ngIf="!hospital">
                        {{ "orderDetail.productNumber" | cxTranslate }}
                      </th>
                      <th *ngIf="hospital">
                        {{ "orderDetail.productUcdNumber" | cxTranslate }}
                      </th>
                      <th>{{ "orderDetail.unitCancelled" | cxTranslate }}</th>
                      <th>{{ "orderDetail.reason" | cxTranslate }}</th>
                    </tr>
                  </thead>
                  <ng-container *ngFor="let data of cancelledEntries">
                    <tbody
                      class="datatable-row desktop-table-consignments"
                      *ngIf="data.quantity > 0"
                    >
                      <tr class="details-col">
                        <td class="text-center">{{ data?.quantityStatus }}</td>
                        <td
                          class="extra-width-20"
                          (click)="openProductDetails(data)"
                          style="
                            color: #0057a6;
                            text-decoration: underline;
                            cursor: pointer;
                          "
                        >
                          {{ data?.product?.name }}
                        </td>
                        <td class="text-center" *ngIf="!hospital">
                          {{ data?.product?.cipCode }}
                        </td>
                        <td class="text-center" *ngIf="hospital">
                          {{ data?.product?.ucdCode }}
                        </td>
                        <td class="text-center">{{ data?.quantity }}</td>
                        <td>
                          {{
                            data.rejectionReason === "Customer Cancellation"
                              ? "Annulation client"
                              : data.rejectionReason
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
              </section>
            </section>
          </section>
        </li>
      </ul>
      <!-- Cancelled Order Product Mobile-->
      <ul class="hidden-md-block" *ngIf="cancelledEntries.length > 0">
        <li>
          <section class="mobile-table">
            <h4 class="headerTextDetailsPage">
              {{ "orderDetail.cancelledProducts" | cxTranslate }}
            </h4>
            <ng-container *ngFor="let data of cancelledEntries">
              <section class="mobile-table-body">
                <p>
                  <label for="orderDetailStatus">{{ "orderDetail.status" | cxTranslate }}</label
                  ><span>{{ data?.quantityStatus }}</span>
                </p>
                <p>
                  <label for="orderDetailProductName">{{ "orderDetail.productName" | cxTranslate }}</label
                  ><span>{{ data?.product?.name }}</span>
                </p>
                <p *ngIf="!hospital">
                  <label for="orderDetailProductNumber">{{ "orderDetail.productNumber" | cxTranslate }}</label
                  ><span>{{ data?.product?.cipCode }}</span>
                </p>
                <p *ngIf="hospital">
                  <label for="orderDetailProductUcdNumber">{{
                    "orderDetail.productUcdNumber" | cxTranslate
                  }}</label
                  ><span>{{ data?.product?.usdCode }}</span>
                </p>
                <p>
                  <label for="orderDetailUnitCancelled">{{ "orderDetail.unitCancelled" | cxTranslate }}</label
                  ><span>{{ data?.quantity }}</span>
                </p>
                <p>
                  <label for="orderDetailReason">{{ "orderDetail.reason" | cxTranslate }}</label
                  ><span>{{ data.rejectionReason }}</span>
                </p>
              </section>
            </ng-container>
          </section>
        </li>
      </ul>
    </section>
  </section>
</section>
