<div class="select-account-popup account-popup" style="display: block; top: 59px;">
  <div class="itc-inner-cont">
    <div class="cust-modal-header">
      <div class="text-center">
        <span>{{'account.selectAWholesaler'|cxTranslate}}</span>
      </div>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12 m-bot10">
      <div class="searchBar-popup m-bot10">
        <span class="searchIcon"></span>
        <input type="text" class="form-control"
               placeholder="{{'account.searchAWwholesalerName'| cxTranslate}}"
               #b2bName
               (keyup)="searchB2BName(b2bName.value)">
      </div>
    </div>
    <div class="cust-modal-body popup-shawdow-line">
      <ng-container *ngIf="getWholesalerLength() == 0 else getWholesaler">
        <div class="noWholesaler">
          {{'account.errorMsgNoWwholesalerName'| cxTranslate}}
        </div>
      </ng-container>
      <ng-container #getWholesaler>
        <div class="radio-btn-section" *ngFor="let b2bUnit of searchedB2bUnitList">
          <label class="radio-container" *ngIf="b2bUnit?.shippingAddresses && (b2bUnit?.name || b2bUnit?.locName)">
            <span class="font-bold com-font-style accName">{{b2bUnit?.locName ? b2bUnit?.locName : b2bUnit?.name}}
            </span>
            <br>
            <span class="font-normal com-font-style">{{b2bUnit?.shippingAddresses[0]?.formattedAddress}}
            </span>
            <input type="radio"
                  class="selectedAccRadio selectedAccRadio0" name="WHS"
                  (change)="getWholesalerId(b2bUnit)">
            <span class="checkmark"></span>
          </label>
        </div>
      </ng-container>
    </div>

    <div class="imc-line-break"></div>
      <div class="cart-modal-footer">
        <ng-container *ngIf="getWholesalerLength() == 0">
          <input type="submit" id="accSubmit" (click)="backToAccountOverview()" 
                  class="btn btn-primary blue-button okCart" value="{{'account.goBack'| cxTranslate}}">
        </ng-container>
        <ng-container *ngIf="getWholesalerLength() > 0">
          <input type="submit" id="accSubmit" (click)="selectWhsForB2BUnit()" [disabled]="!wholesalerId"
              class="btn btn-primary blue-button okCart" value="{{'account.keepOnGoing'| cxTranslate}}">
        </ng-container>
      </div>
  </div>
</div>
